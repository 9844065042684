import type PersonInfo from '~/net/models/person_info'
import http from '~/utils/http'

export class PersonalApi {
  // @POST('/jav/personal/certificateLogin')
  // Future<User> certificateLogin(@Body() CertificateLoginParam param);
  static certificateLogin(params: CertificateLoginParam, opt?: HttpOptions) {
    return http.post<User>({
      ...opt,
      url: '/jav/personal/certificateLogin',
      body: params
    })
  }

  // @POST('/jav/personal/contactList')
  // Future<List<Contact>> contactList();
  static contactList(opt?: HttpOptions) {
    return http.post<Array<Contact>>({ ...opt, url: '/jav/personal/contactList' })
  }

  static info(opt?: HttpOptions) {
    return http.post<PersonInfo>({ ...opt, url: '/jav/personal/info' })
  }

  // @POST('/jav/personal/orderRecord')
  // Future<OrderRecordPageResponse> orderRecord(@Body() OrderRecordQueryParam param);
  static orderRecord(params: OrderRecordQueryParam, opt?: HttpOptions) {
    return http.post<OrderRecordPageResponse>({
      ...opt,
      url: '/jav/personal/orderRecord',
      body: params
    })
  }

  // @POST('/jav/personal/phoneBinding')
  // Future<void> phoneBinding(@Body() JavBindPhoneParam param);
  static phoneBinding(params: JavBindPhoneParam, opt?: HttpOptions) {
    return http.post<any>({
      ...opt,
      url: '/jav/personal/phoneBinding',
      body: params
    })
  }

  // @POST('/jav/personal/phoneLogin')
  // Future<User> phoneLogin(@Body() JavPhoneLoginParam param);
  static phoneLogin(params: JavPhoneLoginParam, opt?: HttpOptions) {
    return http.post<User>({
      ...opt,
      url: '/jav/personal/phoneLogin',
      body: params
    })
  }

  // @POST('/jav/personal/purchaseRecord')
  // Future<PurchaseRecordPageResponse> purchaseRecord(@Body() PurchaseRecordQueryParam param);
  static purchaseRecord(params: PurchaseRecordQueryParam, opt?: HttpOptions) {
    return http.post<PurchaseRecordPageResponse>({
      ...opt,
      url: '/jav/personal/purchaseRecord',
      body: params
    })
  }

  // @POST('/jav/personal/shareInfo')
  // Future<ShareInfo> shareInfo();
  static shareInfo(opt?: HttpOptions) {
    return http.post<ShareInfo>({ ...opt, url: '/jav/personal/shareInfo' })
  }

  // @POST('/jav/personal/shareTotal')
  // Future<int> shareTotal();
  static shareTotal(opt?: HttpOptions) {
    return http.post<number>({ ...opt, url: '/jav/personal/shareTotal' })
  }

  // @POST('/jav/personal/updateInfo')
  // Future<void> updateInfo(@Body() JavUpdateMemberParam param);
  static updateInfo(params: JavUpdateMemberParam, opt?: HttpOptions) {
    return http.post<any>({
      ...opt,
      url: '/jav/personal/updateInfo',
      body: params
    })
  }

  // @POST('/jav/personal/customerAddr')
  // Future<String> customerAddr();
  static customerAddr(opt?: HttpOptions) {
    return http.post<string>({ ...opt, url: '/jav/personal/customerAddr' })
  }

  // @POST('/jav/personal/getJavPayType')
  // Future<Map<String, String>> getJavPayType();
  static getJavPayType(opt?: HttpOptions) {
    return http.post<Record<string, string>>({ ...opt, url: '/jav/personal/getJavPayType' })
  }

  // @POST('/jav/personal/exchangeCDkey')
  // Future<List<ExchangeSkuInfo>> exchangeCDkey(@Body() ExchangeCDKeyParam param);
  static exchangeCDkey(params: ExchangeCDKeyParam, opt?: HttpOptions) {
    return http.post<Array<ExchangeSkuInfo>>({
      ...opt,
      url: '/jav/personal/exchangeCDkey',
      body: params
    })
  }

  // @POST('/jav/personal/exchangeCDkeyRecord')
  // Future<List<ExchangeCDKeyRecord>> exchangeCDkeyRecord(@Body() ExchangeCDKeyRecordParam param);
  static exchangeCDkeyRecord(params: ExchangeCDKeyRecordParam, opt?: HttpOptions) {
    return http.post<Array<ExchangeCDKeyRecord>>({
      ...opt,
      url: '/jav/personal/exchangeCDkeyRecord',
      body: params
    })
  }

  // @POST('/jav/config/queryShareRecordList')
  // Future<List<ShareRecordQuery>> queryShareRecordList(@Body() ShareRecordQuery4List param);
  static queryShareRecordList(params: ShareRecordQuery4List, opt?: HttpOptions) {
    return http.post<Array<ShareRecordQuery>>({
      ...opt,
      url: '/jav/config/queryShareRecordList',
      body: params
    })
  }

  // @POST('/jav/config/countByMember')
  // Future<int> countByMember(@Body() ShareRecordCountByMember param);
  static countByMember(params: ShareRecordCountByMember, opt?: HttpOptions) {
    return http.post<number>({
      ...opt,
      url: '/jav/config/countByMember',
      body: params
    })
  }
}
